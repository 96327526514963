import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { Themes } from 'amphitheatre';
import { Helmet } from 'react-helmet';

import Menu from './Menu';

const Global = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
  }

  body { background-color: ${({ theme }): string => theme.primary200}; }

  #gatsby-focus-wrapper {
    min-height: 100vh;
    position: relative;
    overflow-x: hidden;
  }

  .page-header {
    font-size: 2rem;
    font-weight: bold;

    @media only screen and (min-width: 768px) {
      font-size: 2.5rem;
    }
  }
 `;

const WolfpackTheme = {
  ...Themes.saberLight,
  primary100: '#012331',
  primary200: '#012E40',
  primary300: '#013B52',
  primary400: '#395D73',
  primary500: '#4B7A96',
  primary600: '#6495B2',
  primary700: '#9ABACD',
  primary800: '#D2E0E9',

  menuBgColour: '#012331',
  menuItemActive: '#9ABACD',
  menuItemHover: '#9ABACD',

  fontColour: '#ffffff',

  btnBg: '#6495B2',
  btnBgHover: '#4B7A96',
  btnGhost: 'white',
  btnGhostHover: '#D2E0E9',
  btnLink: '#9ABACD',

  formGroupText: '#012E40',
  formGroupTextError: '#dc3030',
  formGroupBorderError: '#dc3030',
  formGroupBg: 'white',
  formGroupFocusBorder: '#012E40',
  formGroupFocusGlow: '#9ABACD',
};

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  hidden?: boolean;
}

const Layout: React.FunctionComponent<Props> = ({
  children,
  hidden,
}: Props) => {
  return (
    <ThemeProvider theme={WolfpackTheme}>
      <Helmet>
        <meta property="og:url" content="https://www.wolfpackspacehub.com/" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="The Wolfpack Space Hub" />
        <meta property="og:description" content="The Wolfpack Space Hub" />
        <meta
          property="og:image"
          content="https://assets.saberastro.com/img/wolfpack/wolfpack.gif"
        />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <Global />
      <Menu hidden={hidden} />
      {children}
    </ThemeProvider>
  );
};

export default Layout;
